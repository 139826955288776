import React from 'react';
import { Link } from 'gatsby';

import Layout from '@rocketseat/gatsby-theme-docs/src/components/Layout';
import SEO from '@rocketseat/gatsby-theme-docs/src/components/SEO';

export default function NotFound() {
  return (
    <Layout title="Pagina niet gevonden!">
      <SEO title="Pagina niet gevonden" />
      <p>Deze pagina bestaat niet (meer)</p>
      <p>
        <Link to="/">Klik hier</Link> om terug te gaan naar de inleiding.
      </p>
    </Layout>
  );
}
